import React from 'react';
import Loadable from 'react-loadable';

// hooks
import { useAuthLoaded } from '../redux/hooks';

// modules
import { Login } from '../modules/Login';
import { Module404 } from '../modules/404';

// components ::: routes
import { Routes, Route } from 'react-router-dom';
import { Spinner } from '../components/Spinner';

// constants
import * as routes from '../config/routes';

// Loadable module
const ModuleHome = Loadable({
  loader: () => import('./Home/Home'),
  loading: () => <Spinner />,
});

const ModuleReglement = Loadable({
  loader: () => import('./Reglement/Reglement'),
  loading: () => <Spinner />,
});

export const ModulesRoutes: React.FC = () => {
  const authLoaded = useAuthLoaded();

  if (!authLoaded) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path={routes.HOME} element={<ModuleHome />} />
      <Route path={routes.REGLEMENT} element={<ModuleReglement />} />
      
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path="*" element={<Module404 />} />
    </Routes>
  );
};
