import React from 'react';
import classnames from 'classnames';

import { useInterval } from '../../hooks/useInterval';

import { checkSticky, checkHeight } from './Sticky.helpers';
import { optionsEventListener } from './Sticky.constants';

// styles
import './Sticky.scss';

export interface IStickyProps {
  className?: string | null;
  minHeight?: number | undefined;
  children: React.ReactNode;
}

export const Sticky: React.FC<IStickyProps> = ({
  className,
  minHeight,
  children,
}) => {
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  // const [width, setWidth] = React.useState<number | undefined>(undefined);
  const [height, setHeight] = React.useState<number | undefined>(undefined);

  useInterval(() => {
    if (divRef) {
      //checkWidth(divRef.current, setWidth);
      checkHeight(divRef.current, setHeight);
    }
  }, 200);

  const check = () => {
    checkSticky(divRef.current, setIsSticky);
    //checkWidth(divRef.current, setWidth);
    checkHeight(divRef.current, setHeight);
  };

  React.useEffect(() => {
    // checkWidth(divRef.current, setWidth);
    window.addEventListener('scroll', check, optionsEventListener);
    check();
    return () => {
      window.removeEventListener('scroll', check);
    };
  }, []);

  return (
    <div
      className={classnames('sticky', { 'sticky--fixed': isSticky })}
      style={{ minHeight, height }}
      ref={divRef}
    >
      <div className={classnames('sticky-container', className)}>
        {children}
      </div>
    </div>
  );
};

Sticky.defaultProps = {
  className: null,
};
