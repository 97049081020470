import { ACTION_TYPES, TUsersState, IUsersAction } from './users.constants';
import { initialState } from './users.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { fetchItemReducer } from './produceState/fetchItem';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const usersReducer = (
  state: TUsersState = initialState,
  action: IUsersAction = {}
): TUsersState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_ITEM)) {
      return fetchItemReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
