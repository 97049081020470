import { Immutable } from 'immer';

import { IAxiosAction, TPublisherModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TPublisher } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'publishers/FETCH_LIST',
  FETCH_ITEM: 'publishers/FETCH_ITEM',
  CREATE_ITEM: 'publishers/CREATE_ITEM',
  UPDATE_ITEM: 'publishers/UPDATE_ITEM',
};

export type TPublishersState = Immutable<TReduxItems<TPublisher>>;

export type TPublishersData = Array<TPublisherModel> | TPublisherModel;

export type TPublishersMeta = { publisherId: string };

export type IPublishersAction = IAxiosAction<TPublishersData, TPublishersMeta>;
