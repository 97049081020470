import React from 'react';

import { setupAxiosInterceptors } from './axiosInterceptor';

interface IAxiosProviderProps {
  children: React.ReactNode;
}

export const AxiosProvider: React.FC<IAxiosProviderProps> = ({ children }) => {
  setupAxiosInterceptors();

  return <>{children}</>;
};
