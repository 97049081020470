import * as routes from '../config/routes';

export const DEFAULT_EVENT_ID = 'parthenay-2023';

// ***************************************************************************************** EVENTS

export const getEventsListPath = (
  eventId: string = DEFAULT_EVENT_ID
): string => {
  return `${routes.EVENTS_LIST.replace(':eventId', eventId)}`;
};

export const getEventsItemPath = (
  eventId: string = DEFAULT_EVENT_ID
): string => {
  return `${routes.EVENTS_ITEM.replace(':eventId', eventId)}`;
};

export const getEventsTopListPath = (
  eventId: string = DEFAULT_EVENT_ID
): string => {
  return `${routes.EVENTS_TOP_LIST.replace(':eventId', eventId)}`;
};

export const getEventsTopCategoryPath = (
  eventId: string,
  categoryId: string
): string => {
  return `${routes.EVENTS_TOP_CATEGORY.replace(':eventId', eventId).replace(
    ':categoryId',
    categoryId
  )}`;
};

export const getEventsCategoriesPath = (
  eventId: string = DEFAULT_EVENT_ID
): string => {
  return `${routes.EVENTS_CATEGORIES.replace(':eventId', eventId)}`;
};

export const getEventsCategoryPath = (
  eventId: string,
  categoryId: string
): string => {
  return `${routes.EVENTS_CATEGORY.replace(':eventId', eventId).replace(
    ':categoryId',
    categoryId
  )}`;
};

export const getEventsProductPath = (
  eventId: string,
  productId: string
): string => {
  return `${routes.EVENTS_PRODUCT.replace(':eventId', eventId).replace(
    ':productId',
    productId
  )}`;
};

// ***************************************************************************************** PRODUCT

export const getProductPath = (productId: string): string => {
  return `${routes.PRODUCTS_ITEM.replace(':productId', productId)}`;
};

// ***************************************************************************************** ADMIN
export type TAdminType =
  | 'product'
  | 'event'
  | 'category'
  | 'publisher'
  | 'author'
  | 'artist';

export const getAdminProductPath = (productId: string): string => {
  return `${routes.ADMIN_PRODUCT_ITEM.replace(':productId', productId)}`;
};
export const getAdminCategoryPath = (categoryId: string): string => {
  return `${routes.ADMIN_CATEGORY_ITEM.replace(':categoryId', categoryId)}`;
};
export const getAdminEventsPath = (eventId: string): string => {
  return `${routes.ADMIN_EVENT_ITEM.replace(':eventId', eventId)}`;
};
export const getAdminPublisherPath = (publisherId: string): string => {
  return `${routes.ADMIN_PUBLISHER_ITEM.replace(':publisherId', publisherId)}`;
};
export const getAdminAuthorPath = (authorId: string): string => {
  return `${routes.ADMIN_AUTHOR_ITEM.replace(':authorId', authorId)}`;
};
export const getAdminArtistPath = (artistId: string): string => {
  return `${routes.ADMIN_ARTIST_ITEM.replace(':artistId', artistId)}`;
};
export const getAdminListPath = (name: TAdminType): string => {
  switch (name) {
    case 'event':
      return routes.ADMIN_EVENT_LIST;
    case 'category':
      return routes.ADMIN_CATEGORY_LIST;
    case 'product':
      return routes.ADMIN_PRODUCT_LIST;
    case 'publisher':
      return routes.ADMIN_PUBLISHER_LIST;
    case 'author':
      return routes.ADMIN_AUTHOR_LIST;
    case 'artist':
      return routes.ADMIN_ARTIST_LIST;
    default:
      return '';
  }
};

export const getAdminItemPath = (name: TAdminType, id: string): string => {
  const fct = getAdminItemFunction(name);
  return fct ? fct(id) : '';
};

export const getAdminItemFunction = (
  name: TAdminType
): ((id: string) => string) | null => {
  switch (name) {
    case 'event':
      return getAdminEventsPath;
    case 'category':
      return getAdminCategoryPath;
    case 'product':
      return getAdminProductPath;
    case 'publisher':
      return getAdminPublisherPath;
    case 'author':
      return getAdminAuthorPath;
    case 'artist':
      return getAdminArtistPath;
    default:
      return null;
  }
};
