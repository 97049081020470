import { isValidateNumber } from './number';

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export const compareString = (
  value1 = '',
  value2 = '',
  orderBy: OrderBy = OrderBy.ASC
): number => {
  if (value1 === null && value2 === null) {
    return 0;
  } else if (orderBy === OrderBy.DESC) {
    return value2?.localeCompare(value1);
  }
  return value1?.localeCompare(value2);
};

export const compareStringList = (
  value1 = [],
  value2 = [],
  orderBy: OrderBy = OrderBy.ASC
): number => {
  return compareString(value1.join(' '), value2.join(' '), orderBy);
};

export const compareBoolean = (
  value1: boolean,
  value2: boolean,
  orderBy: OrderBy = OrderBy.ASC
): number => {
  if (value1 === value2) {
    return 0;
  } else if (orderBy === OrderBy.DESC) {
    return value1 === false && value2 === true ? 1 : -1;
  } else if (orderBy === OrderBy.ASC) {
    return value1 === false && value2 === true ? -1 : 1;
  }
  return 0;
};

export const VALUE_INFINITE_NUMBER = 100000000;

export const compareNumber = (
  value1: number,
  value2: number,
  orderBy: OrderBy = OrderBy.ASC
): number => {
  const isValidateNumber1 = isValidateNumber(value1);
  const isValidateNumber2 = isValidateNumber(value2);
  if (!isValidateNumber1 && !isValidateNumber2) {
    return 0;
  }
  if (!isValidateNumber1 && isValidateNumber2) {
    return orderBy === OrderBy.DESC
      ? VALUE_INFINITE_NUMBER * -1
      : VALUE_INFINITE_NUMBER;
  }
  if (isValidateNumber1 && !isValidateNumber2) {
    return orderBy === OrderBy.DESC
      ? VALUE_INFINITE_NUMBER
      : VALUE_INFINITE_NUMBER * -1;
  }
  if (orderBy === OrderBy.DESC) {
    return value2 - value1;
  }
  return value1 - value2;
};
