import * as React from 'react';

export const ToastCrossIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9251 4.69159C18.3071 4.30964 18.9264 4.30965 19.3083 4.69162C19.6903 5.07358 19.6902 5.69285 19.3083 6.0748L13.3832 11.9999L19.3084 17.9251C19.6903 18.307 19.6903 18.9264 19.3084 19.3084C18.9264 19.6903 18.307 19.6903 17.9251 19.3084L11.9999 13.3832L6.07488 19.3082C5.69291 19.6901 5.0736 19.6901 4.69163 19.3082C4.30965 18.9262 4.30965 18.3069 4.69162 17.9249L10.6166 11.9999L4.69171 6.07504C4.30974 5.69307 4.30972 5.07377 4.69168 4.69177C5.07366 4.30975 5.69302 4.30973 6.07502 4.69173L11.9999 10.6166L17.9251 4.69159Z"
        fill="currentColor"
      />
    </svg>
  );
};
