import { legacy_createStore as createStore } from 'redux';

// reducers
import { rootReducers } from './reducers';
import { IRootState } from './interface';

// midllewares
import { composedMiddlewares } from './middlewares';

export const getRootStore = (initialState?: IRootState) =>
  createStore(rootReducers, initialState, composedMiddlewares());
