import { Immutable } from 'immer';

import {
  IAxiosAction,
  TEventModel,
  TResultModel,
  TWinnerModel,
} from '../../api/models';
import { TReduxItems } from '../../constants';
import { TEvent, TRatingCategories, TResult, TWinner } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'events/FETCH_LIST',
  FETCH_RATING: 'events/FETCH_RATING',
  FETCH_RESULTS: 'events/FETCH_RESULTS',
  SET_RESULTS_EVENT: 'events/SET_RESULTS_EVENT',
  FETCH_WINNERS: 'events/FETCH_WINNERS',
  SET_WINNERS_EVENT: 'events/SET_WINNERS_EVENT',
  FETCH_ITEM: 'events/FETCH_ITEM',
  CREATE_ITEM: 'events/CREATE_ITEM',
  UPDATE_ITEM: 'events/UPDATE_ITEM',
};

export type TEventRating = TReduxItems<TRatingCategories> & {
  total: number;
  eventId: string;
};

export type TEventResults = TReduxItems<TResult> & {
  eventId: string;
};

export type TEventsWinners = TReduxItems<TWinner> & {
  eventId: string;
};

export type TEventsState = Immutable<
  TReduxItems<TEvent> & {
    rating: Array<TEventRating>;
    results: Array<TEventResults>;
    winners: Array<TEventsWinners>;
  }
>;

export type TEventsData =
  | Array<TEventModel>
  | TEventModel
  | Array<TRatingCategories>
  | Array<TResultModel>
  | Array<TWinnerModel>;

export type TEventsMeta = { eventId: string };

export type IEventsAction = IAxiosAction<TEventsData, TEventsMeta>;
