import produce from 'immer';

import {
  ACTION_TYPES,
  TCategoriesState,
  ICategoriesAction,
} from '../categories.constants';
import { TCategoryModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { fetchItem } from '../../../helpers';
import { compareCategories } from '../events.sort';

// Reducer
export const fetchItemReducer = (
  state: TCategoriesState,
  action: ICategoriesAction
): TCategoriesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ITEM):
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_ITEM):
    case SUCCESS(ACTION_TYPES.CREATE_ITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM):
      const data = action.payload?.data as TCategoryModel;
      const { categoryId } = action.meta || {};
      return produce(state, (draft) => {
        if (categoryId === data.id) {
          draft.items = fetchItem(draft.items, {
            ...data,
            order: data.order || 0,
          });
          draft.items.sort(compareCategories);
        }
      });
    default:
      return state;
  }
};
