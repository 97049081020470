import {
  ACTION_TYPES,
  TAccountState,
  IAccountAction,
} from './account.constants';
import { initialState } from './account.data';

// produce
import { fetchEventRatingReducer } from './produceState/fetchEventRating';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const accountReducer = (
  state: TAccountState = initialState,
  action: IAccountAction = {}
): TAccountState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_EVENT_RATING)) {
      return fetchEventRatingReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.UPDATE_EVENT_PRODUCT_RATING)) {
      return fetchEventRatingReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
