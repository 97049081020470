import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TAccountState,
  getEventRatingDispatch,
  setEmailDispatch,
  updateEventProductRatingDispatch,
} from '../reducers/account';
import { IRootState } from '../interface';
import { useAuth, useAuthExists } from './useAuth';
import { TRating } from '../models';

export interface IUseAccountDispatcher {
  getEventRating: typeof getEventRatingDispatch;
  setEmail: typeof setEmailDispatch;
  updateEventProductRating: typeof updateEventProductRatingDispatch;
}

export const useAccountReducer = (): [TAccountState, IUseAccountDispatcher] => {
  return [useAccountState(), useAccountDispatcher()];
};

export const useAccountState = (): TAccountState => {
  return useSelector<IRootState, TAccountState>((state) => state.account);
};

export const useAccountDispatcher = (): IUseAccountDispatcher => {
  const dispatch = useDispatch();
  return {
    getEventRating: (...args: Parameters<typeof getEventRatingDispatch>) =>
      dispatch(getEventRatingDispatch(...args)),
    setEmail: (...args: Parameters<typeof setEmailDispatch>) =>
      dispatch(setEmailDispatch(...args)),
    updateEventProductRating: (
      ...args: Parameters<typeof updateEventProductRatingDispatch>
    ) => dispatch(updateEventProductRatingDispatch(...args)),
  };
};

export const useAccountRatingByEvent = (eventId: string) => {
  const authExist = useAuthExists();
  const auth = useAuth();
  const [state, dispatch] = useAccountReducer();

  React.useEffect(() => {
    if (authExist && auth?.uid) {
      dispatch.getEventRating(eventId, auth.uid);
    }
  }, []);

  return React.useMemo((): Array<TRating> | null => {
    const ratingItem = state.rating.find((item) => item.eventId === eventId);
    if (ratingItem && ratingItem.status === 'done') {
      const items = state.rating.find(
        (item) => item.eventId === eventId
      )?.items;
      return items ? items.map<TRating>((item) => item) : null;
    }
    return null;
  }, [state, eventId]);
};
