import produce from 'immer';

import {
  ACTION_TYPES,
  TArtistsState,
  IArtistsAction,
} from '../artists.constants';
import { TArtistModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { fetchItem } from '../../../helpers';

// Reducer
export const fetchItemReducer = (
  state: TArtistsState,
  action: IArtistsAction
): TArtistsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ITEM):
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_ITEM):
    case SUCCESS(ACTION_TYPES.CREATE_ITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM):
      const data = action.payload?.data as TArtistModel;
      const { artistId } = action.meta || {};
      return produce(state, (draft) => {
        if (artistId === data.id) {
          draft.items = fetchItem(draft.items, data);
        }
      });
    default:
      return state;
  }
};
