import React from 'react';

import { Provider } from 'react-redux';
//import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
//import { createFirestoreInstance } from 'redux-firestore';

import { getRootStore } from '../../redux/store';
//import { firebase, providerConfig } from '../../config/firebase';

export const store = getRootStore();

interface IReduxStoreProviderProps {
  children: React.ReactNode;
}

export const ReduxStoreProvider: React.FC<IReduxStoreProviderProps> = ({
  children,
}) => (
  <Provider store={store}>
    {/*<ReactReduxFirebaseProvider
      firebase={firebase}
      config={providerConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      {children}
    </ReactReduxFirebaseProvider>*/}
    {children}
  </Provider>
);
