import produce from 'immer';

import {
  ACTION_TYPES,
  TAccountState,
  IAccountAction,
  TAccountRating,
} from '../account.constants';
import { TRating } from '../../../models';
import { TRatingModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

const getNewRating = (
  eventId,
  events: Array<TAccountRating>,
  newEvent: Partial<TAccountRating>
): Array<TAccountRating> => {
  const currentItem = events.find((event) => event.eventId === eventId);
  if (currentItem) {
    return events.map((event) => {
      if (event.eventId === eventId) {
        return {
          ...event,
          ...newEvent,
        };
      }
      return event;
    });
  } else {
    return [
      ...events,
      {
        eventId,
        ids: [],
        items: [],
        status: 'initial',
        ...newEvent,
      },
    ];
  }
};

// Reducer
export const fetchEventRatingReducer = (
  state: TAccountState,
  action: IAccountAction
): TAccountState => {
  const { eventId } = action.meta || {};

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_EVENT_RATING):
      return produce(state, (draft) => {
        draft.rating = getNewRating(eventId, draft.rating, {
          status: 'loading',
        });
      });
    case FAILURE(ACTION_TYPES.FETCH_EVENT_RATING):
      return produce(state, (draft) => {
        draft.rating = getNewRating(eventId, draft.rating, {
          status: 'error',
        });
      });
    case SUCCESS(ACTION_TYPES.FETCH_EVENT_RATING):
      const data = action.payload?.data as Array<TRatingModel>;
      return produce(state, (draft) => {
        draft.rating = getNewRating(eventId, draft.rating, {
          status: 'done',
          items: data.map<TRating>((item) => {
            return { ...item };
          }),
        });
      });
    default:
      return state;
  }
};
