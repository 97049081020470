import {
  ACTION_TYPES,
  TArticlesState,
  IArticlesAction,
} from './articles.constants';
import { initialState } from './artists.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const articlesReducer = (
  state: TArticlesState = initialState,
  action: IArticlesAction = {}
): TArticlesState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
