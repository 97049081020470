import { Metric } from 'web-vitals';

type TRating = 'good' | 'needs-improvement' | 'poor';

export const webVitalsHelpers = {
  getColors(): any {
    return '#dc4549';
  },
  getTitle(): string {
    return 'Web-vitals';
  },
  getLabel({ name, navigationType }: Metric): string {
    return `🦜 ${navigationType} - ${name}`;
  },
  getLogs({ name, id, delta, rating, entries }: Metric): Array<any> {
    return [
      [
        `%cRating: ${getRatingLabel(rating)}`,
        `color: ${getRatingColor(rating)}; font-weight: 700;`,
      ],
      `${name} matching ID "${id}" changed by ${
        name === 'CLS' ? delta * 1000 : delta
      }`,
      entries,
    ];
  },
};

const getRatingLabel = (rating: TRating): string => {
  switch (rating) {
    case 'good':
      return 'Good';
    case 'needs-improvement':
      return 'Needs improvement';
    case 'poor':
      return 'Poor';
  }
};

const getRatingColor = (rating: TRating): string => {
  switch (rating) {
    case 'good':
      return 'green';
    case 'needs-improvement':
      return 'orange';
    case 'poor':
      return 'red';
  }
};
