import { ACTION_TYPES, TEventsState, IEventsAction } from './events.constants';
import { initialState } from './events.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { fetchEventRatingReducer } from './produceState/fetchEventRating';
import { fetchEventResultsReducer } from './produceState/fetchEventResults';
import { fetchEventWinnersReducer } from './produceState/fetchEventWinners';
import { fetchItemReducer } from './produceState/fetchItem';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const eventsReducer = (
  state: TEventsState = initialState,
  action: IEventsAction = {}
): TEventsState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_RATING)) {
      return fetchEventRatingReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_RESULTS)) {
      return fetchEventResultsReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.SET_RESULTS_EVENT)) {
      return fetchEventResultsReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_WINNERS)) {
      return fetchEventWinnersReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.SET_WINNERS_EVENT)) {
      return fetchEventWinnersReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.CREATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.UPDATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
