import produce from 'immer';

import {
  ACTION_TYPES,
  TPublishersState,
  IPublishersAction,
} from '../publishers.constants';

// Reducer
export const transverseReducer = (
  state: TPublishersState,
  action: IPublishersAction
): TPublishersState => {
  switch (action.type) {
    default:
      return state;
  }
};
