import { useContext } from 'react';

import { LayoutContext, ILayoutContext } from './LayoutContext';

export const useLayoutContext = (): ILayoutContext => {
  const context = useContext(LayoutContext) as ILayoutContext;
  if (!context || !context.state || !context.dispatcher) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};

export const useLayoutState = (): ILayoutContext['state'] => {
  const { state } = useLayoutContext();
  return state;
};

export const useLayoutDispatcher = (): ILayoutContext['dispatcher'] => {
  const { dispatcher } = useLayoutContext();
  return dispatcher;
};
