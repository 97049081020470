import produce from 'immer';

import { TLayoutState } from '../../constants';
import { TEvent } from '../../../../../redux/models';

export const setEventReducer = (
  state: TLayoutState,
  action: {
    event: TEvent | null;
  }
): TLayoutState => {
  return produce(state, (draft) => {
    const { event } = action;
    draft.event = event;
  });
};
