import { applyMiddleware, compose } from 'redux';

import { loggerMiddleware } from './logger/logger.middleware';

// External middleware
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';

const defaultMiddlewares = [
  thunkMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  loggerMiddleware,
];

export const composedMiddlewares = (middlewares: Array<any> = []) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));
