import React from 'react';

import './Page404.scss';

export const Page404: React.FC = () => {
  return (
    <div className="page-404">
			<h1>404</h1>
      <div>Page non trouvée</div>
    </div>
  );
};
