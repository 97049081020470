import produce from 'immer';

import { TLayoutState } from '../../constants';

export const setTetiereDisplayReducer = (
  state: TLayoutState,
  action: {
    display: boolean;
  }
): TLayoutState => {
  return produce(state, (draft) => {
    const { display } = action;
    draft.tetiere.display = display;
  });
};
