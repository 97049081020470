import * as ls from 'local-storage';

export const STORAGE_CONSOLELOG_KEY = 'console-log-';

const getKey = (key: string): string => `${STORAGE_CONSOLELOG_KEY}${key}`;

export const get = (key: string): boolean => {
  return ls.get<boolean>(getKey(key)) || false;
};
export const set = (key: string, value: boolean): boolean => {
  if (value === null) {
    remove(key);
    return true;
  }
  return ls.set(getKey(key), value);
};
export const remove = (key: string): void => {
  return ls.remove(getKey(key));
};
