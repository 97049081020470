import React from 'react';

// hooks
import { useLayoutContext } from '../../provider/Layout';

// components
import { Page404 } from '../../components/Page404';

export const Module404: React.FC = () => {
  const layout = useLayoutContext();

  React.useEffect(() => {
    layout.dispatcher?.event.setEvent();
    layout.dispatcher?.tetiere.display(false);
    layout.dispatcher?.tetiere.setInfos();
  }, []);

  return <Page404 />;
};

export default Module404;
