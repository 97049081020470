import produce from 'immer';

import {
  ACTION_TYPES,
  TArtistsState,
  IArtistsAction,
} from '../artists.constants';
import { TArtist } from '../../../models';
import { TArtistModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

// Reducer
export const fetchListReducer = (
  state: TArtistsState,
  action: IArtistsAction
): TArtistsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TArtistModel>;
      return produce(state, (draft) => {
        draft.status = 'done';
        draft.items = data.map<TArtist>((item) => {
          return { ...item };
        });
      });
    default:
      return state;
  }
};
