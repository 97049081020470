import produce from 'immer';

import { ACTION_TYPES, TUsersState, IUsersAction } from '../users.constants';
import { TUserModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { addId, fetchItem, removeId } from '../../../helpers';

// Reducer
export const fetchItemReducer = (
  state: TUsersState,
  action: IUsersAction
): TUsersState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ITEM):
      return produce(state, (draft) => {
        const { userId } = action.meta ?? {};
        draft.ids = addId(draft.ids, userId);
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
      return produce(state, (draft) => {
        const { userId } = action.meta ?? {};
        draft.ids = removeId(draft.ids, userId);
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_ITEM):
      const data = action.payload?.data as TUserModel;
      return produce(state, (draft) => {
        const { userId } = action.meta ?? {};
        if (userId === data.id) {
          draft.items = fetchItem(draft.items, data);
        }
      });
    default:
      return state;
  }
};
