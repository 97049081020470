import { Immutable } from 'immer';

import { IAxiosAction, TUserModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TUser } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'users/FETCH_LIST',
  FETCH_ITEM: 'users/FETCH_ITEM',
};

export type TUsersState = Immutable<TReduxItems<TUser>>;

export type TUsersData = Array<TUserModel> | TUserModel;

export type TUsersMeta = {
  userId: string;
};

export type IUsersAction = IAxiosAction<TUsersData, TUsersMeta>;
