import { Dispatch } from 'redux';
import { IRootState } from './interface';

/* eslint-disable @typescript-eslint/naming-convention */
export const SUFFIX = {
  PENDING: 'PENDING',
  FULLFIELD: 'FULFILLED',
  REJECTED: 'REJECTED',
};

/**
 * Appends REQUEST async action type
 */

export const REQUEST = (actionType: string): string =>
  `${actionType}_${SUFFIX.PENDING}`;

/**
 * Appends SUCCESS async action type
 */

export const SUCCESS = (actionType: string): string =>
  `${actionType}_${SUFFIX.FULLFIELD}`;

/**
 * Appends FAILURE async action type
 */

export const FAILURE = (actionType: string): string =>
  `${actionType}_${SUFFIX.REJECTED}`;

export type IReduxAction<DataType> = {
  type?: string;
  data?: DataType;
};

export type TReduxItem<DataType> = TReduxStatus & {
  item: DataType;
};

export type TReduxItems<DataType> = TReduxStatus & {
  ids?: Array<string>;
  items: Array<DataType>;
};

export type TReduxCurrent<DataType> = {
  current?: DataType;
};

export type TReduxStatus = {
  status: TReducerStatus;
  action?: TReducerAction;
  errorMessage?: string;
};

export type TReducerAction = 'added' | 'updated' | 'deleted';

export type TReducerStatus = 'initial' | 'loading' | 'error' | 'done';

// ***************************************************** DISPATCH
export type TGetterState = () => IRootState;

export type ResponseDispatch = (
  dispatch: Dispatch,
  getState: TGetterState
) => void;

export type TOmitUserModel<T extends { user_uid?: string }> = Omit<
  T,
  'user_uid'
>;

export const getUpdateItem = (item: any) => {
  const { id, path, ...rest } = item;
  return { ...rest, user_uid: '' };
};
