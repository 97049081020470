import { Immutable } from 'immer';

import { IAxiosAction, TAuthorModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TAuthor } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'authors/FETCH_LIST',
  FETCH_ITEM: 'authors/FETCH_ITEM',
  CREATE_ITEM: 'authors/CREATE_ITEM',
  UPDATE_ITEM: 'authors/UPDATE_ITEM',
};

export type TAuthorsState = Immutable<TReduxItems<TAuthor>>;

export type TAuthorsData = Array<TAuthorModel> | TAuthorModel;

export type TAuthorsMeta = { authorId: string };

export type IAuthorsAction = IAxiosAction<TAuthorsData, TAuthorsMeta>;
