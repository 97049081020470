import {
  ACTION_TYPES,
  TProductsState,
  IProductsAction,
} from './products.constants';
import { initialState } from './products.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { fetchListByEventReducer } from './produceState/fetchListByEvent';
import { fetchItemReducer } from './produceState/fetchItem';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const productsReducer = (
  state: TProductsState = initialState,
  action: IProductsAction = {}
): TProductsState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_LIST_BY_EVENT)) {
      return fetchListByEventReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.CREATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.UPDATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
