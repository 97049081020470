import { Immutable } from 'immer';

import { IAxiosAction, TProductModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TProduct } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'products/FETCH_LIST',
  FETCH_LIST_BY_EVENT: 'products/FETCH_EVENT_LIST',
  FETCH_ITEM: 'products/FETCH_ITEM',
  CREATE_ITEM: 'products/CREATE_ITEM',
  UPDATE_ITEM: 'products/UPDATE_ITEM',
};

export type TProductEvent = TReduxItems<TProduct> & { id: string };

export type TProductsState = Immutable<
  TReduxItems<TProduct> & {
    events: Array<TProductEvent>;
  }
>;

export type TProductsData = Array<TProductModel> | TProductModel;

export type TProductsMeta = { productId: string; eventId: string };

export type IProductsAction = IAxiosAction<TProductsData, TProductsMeta>;
