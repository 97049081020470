import {
  ACTION_TYPES,
  TLayoutDispatcher as TDispatcher,
  TLayoutAction as TAction,
  TPanel,
  TTetiereInfos,
} from './constants';
import { TEvent } from '../../../redux/models';

const displayPanel =
  (dispatch: React.Dispatch<TAction>) => (panel: TPanel, display: boolean) => {
    dispatch({
      type: ACTION_TYPES.PANEL.DISPLAY,
      panel,
      display,
    });
  };

const setEventItem =
  (dispatch: React.Dispatch<TAction>) =>
  (event: TEvent | null = null) => {
    dispatch({
      type: ACTION_TYPES.EVENT.SET_ITEM,
      event,
    });
  };

const setdisplayTetiere =
  (dispatch: React.Dispatch<TAction>) => (display: boolean) => {
    dispatch({
      type: ACTION_TYPES.TETIERE.DISPLAY,
      display,
    });
  };

const setInfosTetiere =
  (dispatch: React.Dispatch<TAction>) =>
  (infos: TTetiereInfos | null = null) => {
    dispatch({
      type: ACTION_TYPES.TETIERE.INFOS,
      infos,
    });
  };

const setdisplayNavigation =
  (dispatch: React.Dispatch<TAction>) => (display: boolean) => {
    dispatch({
      type: ACTION_TYPES.NAVIGATION.DISPLAY,
      display,
    });
  };

const setIsAdmin =
  (dispatch: React.Dispatch<TAction>) => (isAdmin: boolean) => {
    dispatch({
      type: ACTION_TYPES.SET_ISADMIN,
      isAdmin,
    });
  };

export const getDispatcher = (
  dispatch: React.Dispatch<TAction>
): TDispatcher => ({
  panel: {
    display: (...args: Parameters<ReturnType<typeof displayPanel>>) =>
      displayPanel(dispatch)(...args),
  },
  event: {
    setEvent: (...args: Parameters<ReturnType<typeof setEventItem>>) =>
      setEventItem(dispatch)(...args),
  },
  tetiere: {
    display: (...args: Parameters<ReturnType<typeof setdisplayTetiere>>) =>
      setdisplayTetiere(dispatch)(...args),
    setInfos: (...args: Parameters<ReturnType<typeof setInfosTetiere>>) =>
      setInfosTetiere(dispatch)(...args),
  },
  navigation: {
    display: (...args: Parameters<ReturnType<typeof setdisplayNavigation>>) =>
      setdisplayNavigation(dispatch)(...args),
  },
  setIsAdmin: (...args: Parameters<ReturnType<typeof setIsAdmin>>) =>
    setIsAdmin(dispatch)(...args),
});
