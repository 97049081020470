import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = '/';

export interface ISetupAxiosInterceptors {
  onUnauthenticated?: () => void;
  onResponseIsNotJson?: () => void;
}

export const setupAxiosInterceptors = ({
  onUnauthenticated,
  onResponseIsNotJson,
}: ISetupAxiosInterceptors = {}) => {
  const onRequestSuccess = (
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig => {
    const newConfig = { ...config };
    return newConfig;
  };
  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    const isJson =
      response.headers['content-type']?.includes('application/json');
    if (onResponseIsNotJson && !isJson) {
      onResponseIsNotJson();
    }
    return response;
  };
  const onResponseError = (err: any) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (onUnauthenticated && status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};
