import {
  ACTION_TYPES,
  TCategoriesState,
  ICategoriesAction,
} from './categories.constants';
import { initialState } from './categories.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { fetchItemReducer } from './produceState/fetchItem';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const categoriesReducer = (
  state: TCategoriesState = initialState,
  action: ICategoriesAction = {}
): TCategoriesState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.CREATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.UPDATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
