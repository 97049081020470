import produce from 'immer';

import {
  ACTION_TYPES,
  TAuthorsState,
  IAuthorsAction,
} from '../authors.constants';
import { TAuthorModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { fetchItem } from '../../../helpers';

// Reducer
export const fetchItemReducer = (
  state: TAuthorsState,
  action: IAuthorsAction
): TAuthorsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ITEM):
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_ITEM):
    case SUCCESS(ACTION_TYPES.CREATE_ITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM):
      const data = action.payload?.data as TAuthorModel;
      const { authorId } = action.meta || {};
      return produce(state, (draft) => {
        if (authorId === data.id) {
          draft.items = fetchItem(draft.items, data);
        }
      });
    default:
      return state;
  }
};
