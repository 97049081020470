import produce from 'immer';

import {
  ACTION_TYPES,
  TEventsState,
  IEventsAction,
  TEventRating,
} from '../events.constants';
import { TRatingCategories } from '../../../models';
import { TRatingCategoriesModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

const getRatingItem = (list: Array<TEventRating>, eventId = '') =>
  list.find((item) => item.eventId === eventId) ?? null;

// Reducer
export const fetchEventRatingReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  const { eventId } = action.meta || {};

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RATING):
      return produce(state, (draft) => {
        if (eventId) {
          const ratingItem = getRatingItem(draft.rating, eventId);

          if (!ratingItem) {
            draft.rating.push({
              eventId,
              status: 'loading',
              total: 0,
              items: [],
            });
          } else {
            ratingItem.status = 'loading';
          }
        }
      });
    case FAILURE(ACTION_TYPES.FETCH_RATING):
      return produce(state, (draft) => {
        if (eventId) {
          const ratingItem = getRatingItem(draft.rating, eventId);
          if (ratingItem) {
            ratingItem.status = 'error';
          }
        }
      });
    case SUCCESS(ACTION_TYPES.FETCH_RATING):
      const data = action.payload?.data as Array<TRatingCategoriesModel>;
      return produce(state, (draft) => {
        if (eventId) {
          const ratingItem = getRatingItem(draft.rating, eventId);
          if (ratingItem) {
            const items = data.map<TRatingCategories>((item) => {
              const totalProducts = item.products.reduce(
                (curProduct, product) => {
                  return curProduct + product.total;
                },
                0
              );
              return { ...item, total: totalProducts };
            });

            const total = items.reduce((curItems, item) => {
              return curItems + item.total;
            }, 0);

            ratingItem.items = items;
            ratingItem.total = total;
            ratingItem.status = 'done';
          }
        }
      });
    default:
      return state;
  }
};
