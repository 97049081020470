import { TEventsState } from './events.constants';

export const initialState: TEventsState = {
  ids: [],
  items: [],
  status: 'initial',
  rating: [],
  results: [],
  winners: [],
};
