import React from 'react';
import classnames from 'classnames';
import { Formik, Form as FormBase, FormikHelpers, FormikProps } from 'formik';

// components
import { Button } from 'reactstrap';
import { BlocLoading } from '../BlocLoading';

// styles
import './Form.scss';

type TChildrenProps<T> = FormikProps<T>;

export interface IFormProps<TValues extends Record<string, any>> {
  className?: string;
  initialValues: TValues | null;
  onCancel?: () => void;
  onSubmit: (values: TValues, formikHelpers: FormikHelpers<TValues>) => void;
  children?:
    | ((props: TChildrenProps<TValues>) => React.ReactNode)
    | React.ReactNode;
}

export const Form: React.FC<IFormProps<any>> = <
  TValues extends Record<string, any>
>({
  className,
  initialValues,
  onCancel,
  onSubmit,
  children,
}: IFormProps<TValues>) => {
  return (
    <div className={classnames("bloc-form", className)}>
      <BlocLoading isLoading={!initialValues}>
        {!!initialValues && (
          <Formik
            enableReinitialize
            validateOnMount={false}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {(props) => {
              return (
                <FormBase>
                  <div className="bloc-form-content">
                    {typeof children === 'function'
                      ? children({ ...props })
                      : children}
                  </div>
                  <div className="bloc-form-submit">
                    {onCancel && (
                      <Button type="button" onClick={onCancel}>
                        Cancel
                      </Button>
                    )}
                    <Button type="submit">Submit</Button>
                  </div>
                </FormBase>
              );
            }}
          </Formik>
        )}
      </BlocLoading>
    </div>
  );
};
