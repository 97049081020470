import produce from 'immer';

import { ACTION_TYPES, TEventsState, IEventsAction } from '../events.constants';

// Reducer
export const transverseReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  switch (action.type) {
    default:
      return state;
  }
};
