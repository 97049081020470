import produce from 'immer';

import { TLayoutState, TPanel } from '../../constants';

export const setPanelDisplayReducer = (
  state: TLayoutState,
  action: {
    panel: TPanel;
    display: boolean;
  }
): TLayoutState => {
  return produce(state, (draft) => {
    const { panel, display } = action;
    if (draft.panel[panel]) {
      draft.panel[panel].display = display;
    }
  });
};
