import { ACTION_TYPES } from './account.constants';
import {
  TRatingProductUpdateModel,
  getRatingByEventAndAuth,
  updateRating,
} from '../../api/rating';

export const getEventRatingDispatch = (eventId: string, authId: string) => {
  return {
    type: ACTION_TYPES.FETCH_EVENT_RATING,
    payload: getRatingByEventAndAuth(eventId, authId),
    meta: { eventId, authId },
  };
};

export const setEmailDispatch = (email: string | null) => {
  return {
    type: ACTION_TYPES.SET_ACCOUNT,
    meta: { email },
  };
};

export const updateEventProductRatingDispatch = (
  eventId: string,
  authId: string,
  productId: string,
  data: TRatingProductUpdateModel
) => {
  return {
    type: ACTION_TYPES.UPDATE_EVENT_PRODUCT_RATING,
    payload: updateRating(eventId, authId, productId, data),
    meta: { eventId, authId, productId },
  };
};
