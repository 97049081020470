import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

// config
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeWebVitals } from './config/webVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

serviceWorkerRegistration.unregister();

initializeWebVitals();