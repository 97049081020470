import React from 'react';
import { Sticky } from '../../components/Sticky';

const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };

export const Header: React.FC = () => {
  const date = new Date();

  return (
    <header className="header-wraper jl_header_magazine_style two_header_top_style header_layout_style3_custom jl_cusdate_head">
    <div className="header_top_bar_wrapper ">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                  <div className="jl_top_bar_right">
                    <span className="jl_current_title"></span>
                    {date.toLocaleDateString('fr', options)}
                  </div>
                </div>
            </div>
        </div>
    </div>
    <Sticky>
      <div id="menu_wrapper" className="menu_wrapper  jl_menu_sticky jl_stick ">        
        <div className="container">
            <div className="row">
                <div className="main_menu col-md-12">
                    <div className="logo_small_wrapper_table">
                      <div className="logo_small_wrapper">
                          <a className="logo_link" href="https://webtoonactu.com/">
                              <img src="https://webtoonactu.com/wp-content/uploads/2022/05/WA-juste-la-bulle.png.webp" alt="Toute l'actu des webtoons" />
                          </a>
                      </div>
                    </div>
                    <div className="search_header_menu">
                      <div className="menu_mobile_share_wrapper">
                        <ul className="social_icon_header_top">
                          <li><a className="facebook" href="https://www.facebook.com/WebtoonActu" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                          <li><a className="instagram" href="https://www.instagram.com/webtoonactu/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
                          <li><a className="twitter" href="https://twitter.com/webtoonactu" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Sticky>
  </header>
  );
};
