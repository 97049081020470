import { ConsoleLogType } from './console-log.constants';

// storage
import { get as getStorage } from '../../storage/consoleLog';

export const getCurrentTimeFormatted = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  const milliseconds = currentTime.getMilliseconds();
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const checkCanWrite = (name: ConsoleLogType) => {
  let canWrite = getStorage(name);
  return canWrite;
};

export const getBooleanLog = (text: string, bool = false): string => {
  return `▫ ${text} ? ${getBooleanString(bool)}`;
};

export const getArrayLog = (text: string, arr: Array<any>): string => {
  return `▫ ${text}: [${arr.join(', ')}]`;
};

export const getArrayListLog = (
  text: string,
  arr: Array<Array<any>>
): string => {
  return `▫ ${text}: ${arr.map((a) => `[${a.join(', ')}]`)}`;
};

export const getBooleanString = (bool = false): string => {
  return bool ? '✔' : '✖';
};
