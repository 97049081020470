import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AxiosProvider } from './Axios';
import { ReduxStoreProvider } from './Redux';
import { LayoutProvider } from './Layout';
import { ErrorBoundary } from '../components/ErrorBoundary';

import './Analytics';

interface IProviderProps {
  children: React.ReactNode;
}

export const Provider: React.FC<IProviderProps> = ({ children }) => {
  return (
    <ErrorBoundary>
      <AxiosProvider>
        <ReduxStoreProvider>
          <BrowserRouter>
            <LayoutProvider>{children}</LayoutProvider>
          </BrowserRouter>
        </ReduxStoreProvider>
      </AxiosProvider>
    </ErrorBoundary>
  );
};
