import produce from 'immer';

import { TLayoutState, TTetiereInfos } from '../../constants';

export const setTetiereInfosReducer = (
  state: TLayoutState,
  action: {
    infos: TTetiereInfos | null;
  }
): TLayoutState => {
  return produce(state, (draft) => {
    const { infos } = action;
    const { img, intro, title, description } = infos || {};
    draft.tetiere.img = img || null;
    draft.tetiere.intro = intro || null;
    draft.tetiere.title = title || null;
    draft.tetiere.description = description || null;
  });
};
