import produce from 'immer';

import {
  ACTION_TYPES,
  TArticlesState,
  IArticlesAction,
} from '../articles.constants';
import { TArticle } from '../../../models';
import { TArticleModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

// Reducer
export const fetchListReducer = (
  state: TArticlesState,
  action: IArticlesAction
): TArticlesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TArticleModel>;
      return produce(state, (draft) => {
        draft.status = 'done';
        draft.items = data.map<TArticle>((item) => {
          return { ...item };
        });
      });
    default:
      return state;
  }
};
