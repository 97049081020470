import produce from 'immer';

import {
  ACTION_TYPES,
  TArtistsState,
  IArtistsAction,
} from '../artists.constants';

// Reducer
export const transverseReducer = (
  state: TArtistsState,
  action: IArtistsAction
): TArtistsState => {
  switch (action.type) {
    default:
      return state;
  }
};
