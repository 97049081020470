export const isValidateNumber = (num: number): boolean => {
  return num !== null && num !== undefined && !isNaN(num);
};

export const round = (number: number, decimal = 2): number => {
  const calcDecimal = Math.pow(10, decimal);
  return Math.round(Number(number) * calcDecimal) / calcDecimal;
};

export const UNITE_NUMBER = {
  GIGA: 'G',
  MEGA: 'M',
  KILO: 'K',
};

export const getRoundNumberToString = (number: number): string => {
  if (number >= 1000000000) {
    return `${round(number / 1000000000, 1)}${UNITE_NUMBER.GIGA}`;
  }
  if (number >= 1000000) {
    return `${round(number / 1000000, 1)}${UNITE_NUMBER.MEGA}`;
  }
  if (number >= 1000) {
    return `${round(number / 1000, 1)}${UNITE_NUMBER.KILO}`;
  }
  return `${number}`;
};

export const getPercentage = (average: number, decimal = 2) => {
  if (!isNaN(average) && average !== null) {
    return `${Number(average.toFixed(decimal))}%`;
  }

  return NaN;
};

export const getAverage = (
  data: Array<Record<string, any>>,
  key = 'value'
): number => {
  let total = 0;
  let tableLength = 0;

  data &&
    data
      .filter((item) => !!item)
      .forEach((item) => {
        if (
          !isNaN(item[key]) &&
          item[key] !== null &&
          item[key] !== undefined
        ) {
          if (typeof item[key] === 'number') {
            total += item[key];
            tableLength += 1;
          } else if (item[key] && typeof item[key] === 'string') {
            total += parseFloat(item[key]);
            tableLength += 1;
          }
        }
      });

  if (total > 0 && tableLength > 0) {
    return Math.round((total / tableLength) * 10) / 10;
  }

  return 0;
};
