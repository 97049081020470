//import { useSelector } from 'react-redux';
//import { isLoaded, isEmpty } from 'react-redux-firebase';

//import { IRootState } from '../interface';
import { TAuth } from '../models/auth';
import { useAccountState } from './useAccount';

export const useAuth = (): TAuth | null => {
  const account = useAccountState();
  return {
    email: account.email || '',
    uid: account.id || '',
    isEmpty: false,
    isLoaded: true,
  };
};

export const useAuthExists = (): boolean => {
  const auth = useAuth();
  return !!auth && auth.isLoaded && !auth.isEmpty && !!auth.uid;
};

export const useAuthLoaded = (): boolean => {
  const auth = useAuth();
  return !!auth && auth.isLoaded;
};
