import React from 'react';
import classnames from 'classnames';
import { useField } from 'formik';

// components
import { FormFeedback, FormGroup as FormGroupBase, FormGroupProps, Label } from 'reactstrap';

// styles
import './FormGroup.scss';

export interface IFormInputProps extends Omit<FormGroupProps, 'className'>{
  className?: string | null;
  name: string;
  label?: React.ReactNode;
  children?: React.ReactNode;
}

export const FormGroup: React.FC<IFormInputProps> = ({
  className,
  name,
  label,
  children,
  ...props
}) => {
  const [, meta] = useField(name);

  const formatedName = React.useMemo(() => {
    return name
      ?.replace('.', '-')
      .replace('[', '-')
      .replace(']', '-')
      .toLowerCase();
  }, [name]);

  return (
    <FormGroupBase
      className={classnames(
        'form-group',
        `form-group--${formatedName}`,
        className
      )}
      {...props}
    >
      {label ? <Label>{label}</Label> : null}
      <div>
        {children}
        {meta && meta.touched && !!meta.error && (
          <FormFeedback invalid="true">{meta.error}</FormFeedback>
        )}
      </div>
    </FormGroupBase>
  );
};

FormGroup.defaultProps = {
  className: null,
  children: null,
};
