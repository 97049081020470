import { Immutable } from 'immer';

import { IAxiosAction, TCategoryModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TCategory } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'categories/FETCH_LIST',
  FETCH_ITEM: 'categories/FETCH_ITEM',
  CREATE_ITEM: 'categories/CREATE_ITEM',
  UPDATE_ITEM: 'categories/UPDATE_ITEM',
};

export type TCategoriesState = Immutable<TReduxItems<TCategory>>;

export type TCategoriesData = Array<TCategoryModel> | TCategoryModel;

export type TCategoriesMeta = { categoryId: string; all: boolean };

export type ICategoriesAction = IAxiosAction<TCategoriesData, TCategoriesMeta>;
