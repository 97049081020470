// axios & redux
import { IReduxAction, SUFFIX as REDUX_SUFFIX } from '../../redux/constants';
import { IAxiosAction } from '../../redux/api/models';
import { IRootState } from '../../redux/interface';

import { FunctionLogType } from './console-log.constants';

export const reduxMiddlewareHelpers = {
  getOriginProcess(type: string): string {
    return type
      .replace(`_${REDUX_SUFFIX.PENDING}`, '')
      .replace(`_${REDUX_SUFFIX.FULLFIELD}`, '')
      .replace(`_${REDUX_SUFFIX.REJECTED}`, '');
  },
  isAsync(type = ''): boolean {
    return (
      typeof type === 'string' &&
      (type.endsWith(`_${REDUX_SUFFIX.PENDING}`) ||
        type.endsWith(`_${REDUX_SUFFIX.FULLFIELD}`) ||
        type.endsWith(`_${REDUX_SUFFIX.REJECTED}`))
    );
  },
  getStyles(type?: string): string {
    if (type && reduxMiddlewareHelpers.isAsync(type)) {
      if (type.endsWith(`_${REDUX_SUFFIX.REJECTED}`)) {
        return 'red';
      }
      if (type.endsWith(`_${REDUX_SUFFIX.PENDING}`)) {
        return 'forestgreen';
      }
      return 'forestgreen';
    }
    return 'sandybrown';
  },
  getTitle(type: string): Array<string> {
    return reduxMiddlewareHelpers.isAsync(type) ? ['api'] : ['redux'];
  },
  getLabel(type: string): string {
    const isAsync = reduxMiddlewareHelpers.isAsync(type);

    const label = isAsync
      ? `${reduxMiddlewareHelpers.getOriginProcess(type)}`
      : `${type}`;

    if (isAsync) {
      if (type.endsWith(`_${REDUX_SUFFIX.PENDING}`)) {
        return `♢♢♢ ${label} ♢♢♢`;
      }
      if (type.endsWith(`_${REDUX_SUFFIX.FULLFIELD}`)) {
        return `♦♦♦ ${label} ♦♦♦`;
      }
      if (type.endsWith(`_${REDUX_SUFFIX.REJECTED}`)) {
        return `❗ ${label} ❗`;
      }
    }

    return label;
  },
  getLogs(
    action: IAxiosAction<any, any> | IReduxAction<any>,
    newState: IRootState
  ): Array<string | Array<string> | (() => FunctionLogType)> {
    const logs: Array<() => FunctionLogType> = [];
    const { type, ...restAction } = action;
    const axiosAction = restAction as IAxiosAction<any, any>;
    const reduxAction = restAction as IReduxAction<any>;
    const isAsync = reduxMiddlewareHelpers.isAsync(type);

    if (isAsync) {
      const { payload, meta, error } = axiosAction;

      if (payload && payload.config) {
        if (payload.config.url) {
          logs.push(
            (): FunctionLogType => ({
              type: 'string',
              data: `url: ${payload.config?.url}`,
            })
          );
        }
        if (payload.config.method) {
          logs.push(
            (): FunctionLogType => ({
              type: 'string',
              data: `method: %c${payload.config?.method?.toUpperCase()}`,
              styles: reduxMiddlewareHelpers.getMethodStyles(
                payload.config?.method
              ),
            })
          );
        }
      }
      if (payload && payload.status) {
        logs.push(
          (): FunctionLogType => ({
            type: 'string',
            data: `status: %c${payload.statusText} (${payload.status})`,
            styles: reduxMiddlewareHelpers.getStatusStyles(payload.status),
          })
        );
      }
      if (error) {
        logs.push(
          (): FunctionLogType => ({
            type: 'group',
            name: 'Error',
            data: payload && payload.toString(),
          })
        );
      }
      if (payload && payload.data) {
        logs.push(
          (): FunctionLogType => ({
            type: 'group',
            name: 'Data',
            data: payload.data,
          })
        );
      } else if (payload && payload.response) {
        if (payload.response.data) {
          logs.push(
            (): FunctionLogType => ({
              type: 'group',
              name: 'Data (response)',
              data: payload?.response?.data,
            })
          );
        }
      }
      if (meta) {
        logs.push(
          (): FunctionLogType => ({
            type: 'group',
            name: 'Meta',
            data: meta,
          })
        );
      }
    } else if (!isAsync) {
      logs.push(
        (): FunctionLogType => ({
          type: 'group',
          name: 'Action',
          data: reduxAction,
        })
      );
    }

    const [reduxName] = type?.split('/') || [];
    const currentState = Object.entries(newState).find(
      ([key]) => key === reduxName
    );

    logs.push(
      (): FunctionLogType => ({
        type: 'group',
        name: `New state (${reduxName})`,
        data: currentState ? currentState[1] : '-',
      })
    );

    return logs;
  },
  getStatusStyles(status = -1): string {
    if (status >= 200 && status <= 299) {
      return 'color: green';
    }
    return 'color: #222';
  },
  getMethodStyles(method = ''): string {
    switch (method) {
      case 'get':
        return 'color: #61affe;font-weight: bold;';
      case 'patch':
        return 'color: #50e3c2;font-weight: bold;';
      case 'post':
        return 'color: #49cc90;font-weight: bold;';
      case 'delete':
        return 'color: #f93e3e;font-weight: bold;';
      case 'put':
        return 'color: #fca130;font-weight: bold;';
    }
    return 'color: #222;';
  },
};
