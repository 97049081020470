import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer id="footer-container" className="enable_footer_columns_dark">
      <div className="footer-columns">
        <div className="container">
          <div className="row">
            <div className="col-md-4">                
              <span className="jl_none_space"></span>
              <div id="disto_about_us_widget-2" className="widget jellywp_about_us_widget">
                <div className="widget_jl_wrapper about_widget_content">
                  <span className="jl_none_space"></span>
                  <div className="widget-title"><h2>A propos de nous </h2></div>
                  <div className="jellywp_about_us_widget_wrapper">
                    <p>Nous sommes des passionnés motivés pour développer des projets sur le secteur des webtoons, pour les faire découvrir et aimer ! Et... vous êtes les bienvenus, même et surtout pour nous aider ;-) </p>
                    <div className="social_icons_widget">
                      <ul className="social-icons-list-widget icons_about_widget_display">
                        <li><a href="https://www.facebook.com/WebtoonActu" className="facebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/webtoonactu/" className="instagram" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
                        <li><a href="https://twitter.com/webtoonactu" className="twitter" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                      </ul>
                    </div>
                  </div>
                  <span className="jl_none_space"></span>
                </div>
              </div>
            </div>
            <div className="col-md-4">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="footer-bottom enable_footer_copyright_dark">
        <div className="container">
          <div className="row bottom_footer_menu_text">
            <div className="col-md-6 footer-left-copyright">© 2022 All Rights Reserved Déferlante Corée &amp; Avanti</div>
            <div className="col-md-6 footer-menu-bottom">
              <ul id="menu-footer-menu" className="menu-footer">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5607"><a href="https://webtoonactu.com/mentions-legales/">Mentions légales</a></li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-5667"><a rel="privacy-policy" href="https://webtoonactu.com/politique-de-confidentialite/">Politique de confidentialité</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-version">
        <span>v{process.env.REACT_APP_VERSION}</span>
      </div>
    </footer>
  );
};
