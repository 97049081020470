import produce from 'immer';

import {
  ACTION_TYPES,
  TEventsState,
  IEventsAction,
  TEventResults,
} from '../events.constants';
import { TResult } from '../../../models';
import { TResultModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

const getResultItem = (list: Array<TEventResults>, eventId = '') =>
  list.find((item) => item.eventId === eventId) || null;

// Reducer
export const fetchEventResultsReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  const { eventId } = action.meta || {};

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RESULTS):
    case REQUEST(ACTION_TYPES.SET_RESULTS_EVENT):
      return produce(state, (draft) => {
        if (eventId) {
          const resultsItem = getResultItem(draft.results, eventId);

          if (!resultsItem) {
            draft.results.push({
              eventId,
              status: 'loading',
              items: [],
            });
          } else {
            resultsItem.status = 'loading';
          }
        }
      });
    case FAILURE(ACTION_TYPES.FETCH_RESULTS):
    case FAILURE(ACTION_TYPES.SET_RESULTS_EVENT):
      return produce(state, (draft) => {
        if (eventId) {
          const resultsItem = getResultItem(draft.results, eventId);
          if (resultsItem) {
            resultsItem.status = 'error';
          }
        }
      });
    case SUCCESS(ACTION_TYPES.FETCH_RESULTS):
    case SUCCESS(ACTION_TYPES.SET_RESULTS_EVENT):
      const data = action.payload?.data as Array<TResultModel>;
      return produce(state, (draft) => {
        if (eventId) {
          const resultsItem = getResultItem(draft.results, eventId);
          if (resultsItem) {
            const items = data.map<TResult>((item) => {
              return { ...item };
            });
            resultsItem.items = items;
            resultsItem.status = 'done';
          }
        }
      });
    default:
      return state;
  }
};
