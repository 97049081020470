import produce from 'immer';

import { ACTION_TYPES, TEventsState, IEventsAction } from '../events.constants';
import { TEventModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { addId, fetchItem, removeId } from '../../../helpers';

// Reducer
export const fetchItemReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ITEM):
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        const { eventId } = action.meta ?? {};
        draft.ids = addId(draft.ids, eventId);
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM):
      return produce(state, (draft) => {
        const { eventId } = action.meta ?? {};
        draft.ids = removeId(draft.ids, eventId);
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_ITEM):
    case SUCCESS(ACTION_TYPES.CREATE_ITEM):
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM):
      const data = action.payload?.data as TEventModel;
      const { eventId } = action.meta ?? {};
      return produce(state, (draft) => {
        if (eventId === data.id) {
          draft.items = fetchItem(draft.items, data);
        }
      });
    default:
      return state;
  }
};
