import React from 'react';
import { LayoutContext } from './context';
import {
  TLayoutState,
  defaultState,
  getDispatcher,
  layoutReducer,
} from './reducer';

import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorBoundary';

// constants
import { ADMIN } from '../../config/routes';

interface ILayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider: React.FC<ILayoutProviderProps> = ({
  children,
}) => {
  const location = useLocation();

  const animateCss: TLayoutState['animate'] =
    location.pathname === '/' ? 'animate2' : 'animate';

  const initialeState: TLayoutState = {
    ...defaultState,
    animate: animateCss,
    isAdmin: false,
  };

  const [state, dispatch] = React.useReducer(layoutReducer, initialeState);

  const dispatcher = getDispatcher(dispatch);

  const provierValue = React.useMemo(() => {
    return { state, dispatcher }
  }, [state, dispatcher])

  /*
  React.useEffect(() => {
    // HOME
    if(location.pathname === '/') {
      dispatcher.navigation.display(false);
    // OTHER MODULES
    } else {
      dispatcher.navigation.display(true);
    }
  }, [location.pathname]);
  */

  React.useEffect(() => {
    const isAdmin: TLayoutState['isAdmin'] = location.pathname.includes(ADMIN);
    dispatcher.setIsAdmin(isAdmin);
  }, [location.pathname]);

  return (
    <LayoutContext.Provider value={provierValue}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </LayoutContext.Provider>
  );
};
