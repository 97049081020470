import React from 'react';

import { ToastCrossIcon } from '../../icon';

export interface IToastCloseIconProps {
  type: string;
}

export const ToastCloseIcon: React.FC<IToastCloseIconProps> = () => {
  return (
    <button className="Toastify__close-button Toastify__close-button--colored">
      <ToastCrossIcon />
    </button>
  );
};
