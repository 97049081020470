import axios, { AxiosPromise } from 'axios';
import { TRatingProductUpdateModel } from './rating.model';

export const API_RATING = 'api/rating';

export const getRatingByEvent = (eventId: string): AxiosPromise<any> =>
  axios.get<any>(`${API_RATING}/${eventId}`);

export const getRatingCategoriesByEvent = (
  eventId: string
): AxiosPromise<any> => axios.get<any>(`${API_RATING}/${eventId}/categories`);

export const getRatingByEventAndAuth = (
  eventId: string,
  authId: string
): AxiosPromise<any> =>
  axios.get<any>(`${API_RATING}/${eventId}/auth/${authId}`);

export const updateRating = (
  eventId: string,
  authId: string,
  productId: string,
  data: TRatingProductUpdateModel
): AxiosPromise<any> =>
  axios.patch<any>(`${API_RATING}/${eventId}/auth/${authId}/${productId}`, {
    ...data,
    auth_email: atob(authId),
  });
