import { Immutable } from 'immer';

import { IAxiosAction, TArtistModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TArtist } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'artists/FETCH_LIST',
  FETCH_ITEM: 'artists/FETCH_ITEM',
  CREATE_ITEM: 'artists/CREATE_ITEM',
  UPDATE_ITEM: 'artists/UPDATE_ITEM',
};

export type TArtistsState = Immutable<TReduxItems<TArtist>>;

export type TArtistsData = Array<TArtistModel> | TArtistModel;

export type TArtistsMeta = { artistId: string };

export type IArtistsAction = IAxiosAction<TArtistsData, TArtistsMeta>;
