import React from 'react';

import { Provider } from './provider';
import { Layout } from './layout';
import { ModulesRoutes } from './modules/Routes';

import './App.scss';

export const App: React.FC = () => {
  return (
    <Provider>
      <Layout>
        <ModulesRoutes />
      </Layout>
    </Provider>
  );
};
