import React from 'react';
import { ToastContainer as ReactToastContainer, toast } from 'react-toastify';

import { ToastCloseIcon } from './ToastCloseIcon';
import { ToastTypeIcon } from './ToastTypeIcon';

/*
const transition = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  appendPosition: false,
  collapse: true,
  collapseDuration: 300,
});
*/

import './Toast.scss';

export const ToastContainer: React.FC = () => {
  return (
    <ReactToastContainer
      className="toastify-container"
      hideProgressBar
      pauseOnHover
      limit={1}
      theme="colored"
      position={toast?.POSITION?.BOTTOM_CENTER}
      closeButton={ToastCloseIcon}
      icon={ToastTypeIcon}
    />
  );
};
