import React from 'react';
import { Spinner as SpinnerStrap } from 'reactstrap';

import './Spinner.scss';

export type SpinnerSize = 'small' | 'xsmall' | 'standard';
interface ISpinnerProps {
  size?: SpinnerSize;
}

export const Spinner: React.FC<ISpinnerProps> = ({ size }) => {
  switch (size) {
    case 'xsmall':
      return <SpinnerXXS />;
    case 'small':
      return <SpinnerXS />;
    default:
      return <SpinnerModule />;
  }
};

export const SpinnerXS: React.FC = () => (
  <div className="spinner spinner-xs">
    <SpinnerStrap color="primary" />
  </div>
);

export const SpinnerXXS: React.FC = () => (
  <div className="spinner spinner-xxs">
    <SpinnerStrap color="primary" />
  </div>
);

export const SpinnerModule: React.FC = () => (
  <div className="spinner-container">
    <SpinnerStrap color="primary" />
  </div>
);

Spinner.defaultProps = {
  size: 'standard',
};
