import produce from 'immer';

import {
  ACTION_TYPES,
  TAccountState,
  IAccountAction,
} from '../account.constants';

import { set as setUser } from '../../../../storage/user';

// Reducer
export const transverseReducer = (
  state: TAccountState,
  action: IAccountAction
): TAccountState => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACCOUNT:
      return produce(state, (draft) => {
        const { email } = action.meta || {};
        draft.email = email || null;
        draft.id = email ? btoa(email) : null;
        draft.isAdmin = false;

        setUser(draft.id); // if null, remove storage
      });
    default:
      return state;
  }
};
