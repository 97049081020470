import React from 'react';
import { toast } from 'react-toastify';

import { ToastSuccessIcon, ToastErrorIcon } from '../../icon';

export interface IToastTypeIconProps {
  type: string;
}

export const ToastTypeIcon: React.FC<IToastTypeIconProps> = ({ type }) => {
  switch (type) {
    case toast.TYPE.SUCCESS:
      return <ToastSuccessIcon />;
    case toast.TYPE.ERROR:
      return <ToastErrorIcon />;
    default:
      return null;
  }
};
