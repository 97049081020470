import React from 'react';

// styles
import './ErrorBoundary.scss';

export interface IErrorBoundaryProps {
  readonly children: React.ReactNode | Array<React.ReactNode>;
  img?: React.ReactNode;
  customError?: React.ReactNode;
}

type ErrorBoundaryState = {
  readonly error: any;
  readonly errorInfo: any;
};

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  ErrorBoundaryState
> {
  readonly state: ErrorBoundaryState = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { customError, children } = this.props;

    if (customError && errorInfo) {
      return <>{customError}</>;
    } else if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details className="preserve-space">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined;

      return <div className="error-boundary">{errorDetails}</div>;
    }
    return children;
  }
}
