import { IRootState } from '../interface';

import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
//import { firebaseReducer } from 'react-redux-firebase';
//import { firestoreReducer } from 'redux-firestore';

import { eventsReducer } from './events';
import { publishersReducer } from './publishers';
import { authorsReducer } from './authors';
import { artistsReducer } from './artists';
import { articlesReducer } from './articles';
import { categoriesReducer } from './categories';
import { productsReducer } from './products';
import { accountReducer } from './account';
import { usersReducer } from './users';

export const rootReducers = combineReducers<IRootState>({
  loadingBar: loadingBarReducer,
  //firebase: firebaseReducer,
  //firestore: firestoreReducer,
  events: eventsReducer,
  publishers: publishersReducer,
  authors: authorsReducer,
  artists: artistsReducer,
  articles: articlesReducer,
  categories: categoriesReducer,
  products: productsReducer,
  account: accountReducer,
  users: usersReducer,
});
