import { Context, createContext } from 'react';

import { TLayoutState, TLayoutDispatcher } from '../reducer';

export interface ILayoutContext {
  state: TLayoutState | null;
  dispatcher: TLayoutDispatcher | null;
}

export const LayoutContext: Context<ILayoutContext | {}> = createContext({});
