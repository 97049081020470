import { Immutable } from 'immer';

import { IAxiosAction, TRatingModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TRating } from '../../models';

export const ACTION_TYPES = {
  FETCH_EVENT_RATING: 'account/FETCH_EVENT_RATING',
  UPDATE_EVENT_PRODUCT_RATING: 'account/UPDATE_EVENT_PRODUCT_RATING',
  SET_ACCOUNT: 'account/SET_ACCOUNT',
};

export type TAccountRating = TReduxItems<TRating> & { eventId: string };

export type TAccountState = Immutable<{
  rating: Array<TAccountRating>;
  isAdmin: boolean;
  id: string | null;
  email: string | null;
}>;

export type TAccountData = Array<TRatingModel>;

export type TAccountMeta = {
  eventId?: string;
  authId?: string;
  productId?: string;
  email?: string | null;
};

export type IAccountAction = IAxiosAction<TAccountData, TAccountMeta>;
