import { TCategory } from '../../models';
import {
  OrderBy,
  compareNumber,
  compareString,
} from '../../../helpers/compare';

export const compareCategories = (
  category1: TCategory,
  category2: TCategory,
  orderBy = OrderBy.ASC
): number => {
  let compare = 0;

  compare = compareNumber(category1.order, category2.order, orderBy);

  if (compare === 0) {
    compare = compareString(category1.title, category2.title, orderBy);
  }

  return compare;
};
