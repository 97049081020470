import { TEvent, TRating } from '../../models';
import {
  OrderBy,
  compareNumber,
  compareString,
} from '../../../helpers/compare';

export const compareEvent = (
  event1: TEvent,
  event2: TEvent,
  orderBy = OrderBy.ASC
): number => {
  let compare = 0;

  compare = compareString(event1.name, event2.name, orderBy);

  return compare;
};

export const compareEventRating = (
  rating1: TRating,
  rating2: TRating,
  orderBy = OrderBy.DESC
): number => {
  let compare = 0;

  compare = compareNumber(rating1.vote, rating2.vote, orderBy);

  return compare;
};
