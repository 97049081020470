import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountDispatcher, useAuthExists } from '../../redux/hooks';

//import { StyledFirebaseAuth } from 'react-firebaseui';

//import { auth, authConfig } from '../../config/firebase';

import { Form, FormInput } from '../../components/Form';

import * as routes from '../../config/routes';
import { getEventsCategoriesPath } from '../../helpers/path';

import './Login.scss';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const isAuth = useAuthExists();
  const dispatch = useAccountDispatcher()

  React.useEffect(() => {
    if(isAuth) {
      navigate(getEventsCategoriesPath());
    }
  }, [isAuth]);

  const initialValues = {
    email: ''
  }

  const handleCancel = () => {
    navigate(routes.JEU_GRAND_PRIX_PUBLIC);
  }

  const handleSubmit = (values) => {
    dispatch.setEmail(values.email);
    //navigate(routes.JEU_GRAND_PRIX_PUBLIC);
  }

  return (
    <div className="login-module">
      <p>Enregistrez votre email pour pouvoir participer au concours.</p>
      <p>Pour le tirage au sort, il est nécessaire de renseigner un mail valide.</p>
      <Form
        initialValues={initialValues}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      >
        <FormInput name="email" type="email" placeholder="Insérez votre email." />
      </Form>
      {/* <StyledFirebaseAuth uiConfig={authConfig} firebaseAuth={auth} /> */}
    </div>
  );
};

export default Login;
