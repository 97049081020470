export const HOME = '/';

export const INFOS = '/infos';
export const REGLEMENT = '/reglement';
export const JEU_GRAND_PRIX_PUBLIC = '/jeu-grand-prix-public';

export const SUMMARY_PRODUCTS = '/summary/products';
export const SUMMARY_EVENTS = '/summary/events';

export const PRODUCTS_ITEM = '/products/:productId';

export const EVENTS_LIST = '/events';
export const EVENTS_ITEM = EVENTS_LIST + '/:eventId';
export const EVENTS_PRODUCTS = EVENTS_LIST + '/:eventId/products';
export const EVENTS_PRODUCT = EVENTS_LIST + '/:eventId/products/:productId';
export const EVENTS_WINNERS = EVENTS_LIST + '/:eventId/winners';
export const EVENTS_CATEGORIES = EVENTS_LIST + '/:eventId/categories';
export const EVENTS_CATEGORY = EVENTS_LIST + '/:eventId/categories/:categoryId';
export const EVENTS_TOP_LIST = EVENTS_LIST + '/:eventId/top';
export const EVENTS_TOP_CATEGORY = EVENTS_LIST + '/:eventId/top/:categoryId';
export const EVENTS_QRCODE_CATEGORY =
  EVENTS_LIST + '/:eventId/qrcode/categories/:categoryId';
export const EVENTS_QRCODE_PRODUCT =
  EVENTS_LIST + '/:eventId/qrcode/product/:productId';

export const ADMIN = '/admin';
export const ADMIN_HOME = ADMIN + '/home';
export const ADMIN_PRODUCT_LIST = ADMIN + '/product';
export const ADMIN_PRODUCT_CATEGORY_LIST =
  ADMIN + '/product/category/:categoryId';
export const ADMIN_PRODUCT_ITEM = ADMIN + '/product/:productId';
export const ADMIN_CATEGORY_LIST = ADMIN + '/category';
export const ADMIN_CATEGORY_ITEM = ADMIN + '/category/:categoryId';
export const ADMIN_EVENT_LIST = ADMIN + '/event';
export const ADMIN_EVENT_ITEM = ADMIN + '/event/:eventId';
export const ADMIN_PUBLISHER_LIST = ADMIN + '/publisher';
export const ADMIN_PUBLISHER_ITEM = ADMIN + '/publisher/:publisherId';
export const ADMIN_AUTHOR_LIST = ADMIN + '/author';
export const ADMIN_AUTHOR_ITEM = ADMIN + '/author/:authorId';
export const ADMIN_ARTIST_LIST = ADMIN + '/artist';
export const ADMIN_ARTIST_ITEM = ADMIN + '/artist/:artistId';

export const LOGIN = '/login';
