import * as React from 'react';

export const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg className="checked" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 0C4.03579 0 0 4.03579 0 9C0 13.9642 4.03579 18 9 18C13.9642 18 18 13.9642 18 9C18 4.03579 13.9642 0 9 0ZM12.9032 7.40842L8.81053 12.2779C8.64 12.4863 8.39368 12.6 8.12842 12.6C7.86316 12.6 7.61684 12.4863 7.44632 12.2779L5.09684 9.47368C4.77474 9.09474 4.83158 8.54526 5.21053 8.22316C5.58947 7.90105 6.13895 7.95789 6.46105 8.33684L8.12842 10.3263L11.5389 6.27158C11.8611 5.89263 12.4105 5.85474 12.7895 6.1579C13.1684 6.48 13.2253 7.02947 12.9032 7.40842Z"
        fill="#7EE2B3"
      />
    </svg>
  );
};
