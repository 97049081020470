// helpers
import { reduxMiddlewareHelpers } from './console-log.redux';
import { webVitalsHelpers } from './console-log.webvitals';

import { getCurrentTimeFormatted, checkCanWrite } from './console-log.helpers';

// constants
import { ConsoleLogType, FunctionLogType } from './console-log.constants';

/* eslint no-console: off */
export const writeGroupCollapsed = (
  title: string | Array<string>,
  label: string,
  logs: Array<any> | null,
  colors: string | Array<string> = 'green'
): void => {
  if (logs && logs.length) {
    // construct args parameter of groupCollapsed functionality
    const sTitle = Array.isArray(title)
      ? title.filter((sT) => !!sT).join('][')
      : title;
    const args = [
      `▪ %c[${sTitle}] %c ${label} - %cat ${getCurrentTimeFormatted()}`,
      `color: ${Array.isArray(colors) ? colors[0] : colors}`,
      'color: black',
      'color: lightblue; font-weight: lighter;',
    ];
    if (Array.isArray(colors)) {
      colors.slice(1).forEach((color) => {
        args.push(`color: ${color}`);
      });
    }

    console.groupCollapsed.apply(null, args);
    logs.forEach((log) => {
      if (log) {
        if (typeof log === 'function') {
          writeFunctionSpecificLog(log());
        } else if (Array.isArray(log)) {
          console.log.apply(null, log);
        } else {
          console.log(log);
        }
      }
    });
    console.groupEnd();
  }
};

export const writeFunctionSpecificLog = (log: FunctionLogType): void => {
  try {
    switch (log.type) {
      case 'group': {
        console.groupCollapsed(log.name || '*****');
        console.log(log.data);
        console.groupEnd();
        break;
      }
      case 'string': {
        if (Array.isArray(log.data)) {
          console.log.apply(null, log.data);
        } else {
          console.log(log.data, log.styles || '');
        }
        break;
      }
    }
  } catch (err) {
    console.warn(err);
  }
};

export const writeReduxMiddlewareGroupCollapsed = (
  action: any,
  newState: any
): void => {
  if (!checkCanWrite(ConsoleLogType.REDUX)) {
    return;
  }
  const { type } = action as { type: string };
  if (
    type &&
    !type.startsWith('loading/') &&
    !type.startsWith('loading-bar/')
  ) {
    writeGroupCollapsed(
      reduxMiddlewareHelpers.getTitle(type),
      reduxMiddlewareHelpers.getLabel(type),
      reduxMiddlewareHelpers.getLogs(action, newState),
      reduxMiddlewareHelpers.getStyles(type)
    );
  }
};

export const writeWebVitalsGroupCollapsed = (entry: any): void => {
  if (!checkCanWrite(ConsoleLogType.WEB_VITALS)) {
    return;
  }
  writeGroupCollapsed(
    webVitalsHelpers.getTitle(),
    webVitalsHelpers.getLabel(entry),
    webVitalsHelpers.getLogs(entry),
    webVitalsHelpers.getColors()
  );
};
