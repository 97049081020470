export enum ConsoleLogType {
  REDUX = 'redux-middleware',
  WEB_VITALS = 'web-vitals',
}

export type FunctionLogType = {
  type: 'group' | 'string';
  name?: string;
  data: any;
  styles?: string;
};
