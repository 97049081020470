import React from 'react';
import classnames from 'classnames';

// components
import { SpinnerXS } from '../Spinner';

import './BlocLoading.scss';

export interface IBlocLoadingProps {
  className?: string | null;
  isLoading?: boolean;
  hasEffect?: boolean;
  withoutLoading?: boolean;
  children: React.ReactNode;
}

export const BlocLoading: React.FC<IBlocLoadingProps> = ({
  className = null,
  isLoading = true,
  hasEffect = false,
  withoutLoading,
  children,
}) => {
  const [initEffect, setInitEffect] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (hasEffect && !isLoading) {
      setInitEffect(true);
    }
  }, [hasEffect, isLoading]);

  if (withoutLoading) {
    return <>{children}</>;
  }

  return (
    <div
      className={classnames(
        'bloc-loading',
        {
          'bloc-loading--active': isLoading,
          'has-effect': hasEffect,
          effect: initEffect,
        },
        className
      )}
    >
      <div className="bloc-loading_spinner">
        <SpinnerXS />
      </div>
      {children}
    </div>
  );
};

BlocLoading.defaultProps = {
  className: null,
  isLoading: true,
  hasEffect: false,
  withoutLoading: false,
};
