export const fetchItem = <T extends Record<string, any> & { id: string }>(
  list: Array<T>,
  newItem: T
) => {
  if (!!newItem) {
    const oldItem = list.find((item) => item.id === newItem.id);
    if (oldItem) {
      return list.map((item) => {
        if (item.id === newItem.id) {
          return newItem;
        } else {
          return item;
        }
      });
    } else {
      return [...list, newItem];
    }
  }

  return list;
};

export const addId = (
  list: Array<string> | undefined,
  id: string | undefined
) => {
  if (id && list && !list.includes(id)) {
    return [...list, id];
  }
  return [...(list ?? [])];
};

export const removeId = (
  list: Array<string> | undefined,
  id: string | undefined
) => {
  if (id && list && list.includes(id)) {
    return [...list].filter((s) => s !== id);
  }
  return [...(list ?? [])];
};
