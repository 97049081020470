import {
  ACTION_TYPES,
  TPublishersState,
  IPublishersAction,
} from './publishers.constants';
import { initialState } from './publishers.data';

// produce
import { fetchListReducer } from './produceState/fetchList';
import { fetchItemReducer } from './produceState/fetchItem';
import { transverseReducer } from './produceState/transverse';

// Reducer
export const publishersReducer = (
  state: TPublishersState = initialState,
  action: IPublishersAction = {}
): TPublishersState => {
  if (action.type) {
    if (action.type.includes(ACTION_TYPES.FETCH_LIST)) {
      return fetchListReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.FETCH_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.CREATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
    if (action.type.includes(ACTION_TYPES.UPDATE_ITEM)) {
      return fetchItemReducer(state, action);
    }
  }
  return transverseReducer(state, action);
};
