import produce from 'immer';

import {
  ACTION_TYPES,
  TEventsState,
  IEventsAction,
  TEventsWinners,
} from '../events.constants';
import { TWinner } from '../../../models';
import { TWinnerModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

const getWinnerItem = (list: Array<TEventsWinners>, eventId = '') =>
  list.find((item) => item.eventId === eventId) || null;

// Reducer
export const fetchEventWinnersReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  const { eventId } = action.meta || {};

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_WINNERS):
    case REQUEST(ACTION_TYPES.SET_WINNERS_EVENT):
      return produce(state, (draft) => {
        if (eventId) {
          const winnersItem = getWinnerItem(draft.winners, eventId);

          if (!winnersItem) {
            draft.winners.push({
              eventId,
              status: 'loading',
              items: [],
            });
          } else {
            winnersItem.status = 'loading';
          }
        }
      });
    case FAILURE(ACTION_TYPES.FETCH_WINNERS):
    case FAILURE(ACTION_TYPES.SET_WINNERS_EVENT):
      return produce(state, (draft) => {
        if (eventId) {
          const winnersItem = getWinnerItem(draft.winners, eventId);
          if (winnersItem) {
            winnersItem.status = 'error';
          }
        }
      });
    case SUCCESS(ACTION_TYPES.FETCH_WINNERS):
    case SUCCESS(ACTION_TYPES.SET_WINNERS_EVENT):
      const data = action.payload?.data as Array<TWinnerModel>;
      return produce(state, (draft) => {
        if (eventId) {
          const winnersItem = getWinnerItem(draft.winners, eventId);
          if (winnersItem) {
            const items = data.map<TWinner>((item) => {
              return { ...item };
            });
            winnersItem.items = items;
            winnersItem.status = 'done';
          }
        }
      });
    default:
      return state;
  }
};
