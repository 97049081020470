import produce from 'immer';

import {
  ACTION_TYPES,
  TProductsState,
  IProductsAction,
} from '../products.constants';
import { TProduct } from '../../../models';
import { TProductModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { compareProduct } from '../../products/products.sort';

// Reducer
export const fetchListReducer = (
  state: TProductsState,
  action: IProductsAction
): TProductsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TProductModel>;
      return produce(state, (draft) => {
        const items = data.map<TProduct>((item) => {
          return { ...item };
        });
        items.sort(compareProduct);

        draft.status = 'done';
        draft.items = items;
      });
    default:
      return state;
  }
};
