import { Immutable } from 'immer';

import { IAxiosAction, TArticleModel } from '../../api/models';
import { TReduxItems } from '../../constants';
import { TArticle } from '../../models';

export const ACTION_TYPES = {
  FETCH_LIST: 'articles/FETCH_LIST',
  FETCH_ITEM: 'articles/FETCH_ITEM',
};

export type TArticlesState = Immutable<TReduxItems<TArticle>>;

export type TArticlesData = Array<TArticleModel> | TArticleModel;

export type TArticlesMeta = { category: string };

export type IArticlesAction = IAxiosAction<TArticlesData, TArticlesMeta>;
