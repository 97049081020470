import * as React from 'react';

export const ToastErrorIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4.8898 20H19.1102C20.5644 20 21.4708 18.4289 20.7438 17.1777L13.6336 4.93841C12.9065 3.6872 11.0935 3.6872 10.3664 4.93841L3.25624 17.1777C2.52917 18.4289 3.43565 20 4.8898 20ZM12 13.4147C11.4807 13.4147 11.0557 12.9913 11.0557 12.4739V10.5924C11.0557 10.075 11.4807 9.65162 12 9.65162C12.5193 9.65162 12.9443 10.075 12.9443 10.5924V12.4739C12.9443 12.9913 12.5193 13.4147 12 13.4147ZM12.9443 17.1777H11.0557V15.2962H12.9443V17.1777Z"
        fill="#DB222A"
      />
    </svg>
  );
};
