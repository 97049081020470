import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const analytics = Analytics({
  app: 'akiltour',
  plugins: [
    googleTagManager({
      containerId: 'G-VYZ7YSSGSH',
    }),
  ],
});

/* Track a page view */
analytics.page();
