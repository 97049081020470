// constants
import { ACTION_TYPES, TLayoutState, TLayoutAction } from './constants';

// produceState
import { setPanelDisplayReducer } from './produceState/panel/display';
import { setEventReducer } from './produceState/event/setEvent';
import { setTetiereDisplayReducer } from './produceState/tetiere/display';
import { setTetiereInfosReducer } from './produceState/tetiere/infos';
import { setNavigationDisplayReducer } from './produceState/navigation/display';
import { setIsAdminReducer } from './produceState/admin/setIsAdmin';

export const layoutReducer = (
  state: TLayoutState,
  action: TLayoutAction
): TLayoutState => {
  switch (action.type) {
    case ACTION_TYPES.PANEL.DISPLAY: {
      return setPanelDisplayReducer(state, action as any);
    }
    case ACTION_TYPES.EVENT.SET_ITEM: {
      return setEventReducer(state, action as any);
    }
    case ACTION_TYPES.TETIERE.DISPLAY: {
      return setTetiereDisplayReducer(state, action as any);
    }
    case ACTION_TYPES.TETIERE.INFOS: {
      return setTetiereInfosReducer(state, action as any);
    }
    case ACTION_TYPES.NAVIGATION.DISPLAY: {
      return setNavigationDisplayReducer(state, action as any);
    }
    case ACTION_TYPES.SET_ISADMIN: {
      return setIsAdminReducer(state, action as any);
    }
    default: {
      return { ...state };
    }
  }
};
