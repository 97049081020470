import produce from 'immer';

import { TLayoutState } from '../../constants';

export const setIsAdminReducer = (
  state: TLayoutState,
  action: {
    isAdmin: boolean;
  }
): TLayoutState => {
  return produce(state, (draft) => {
    const { isAdmin } = action;
    draft.isAdmin = isAdmin;
  });
};
