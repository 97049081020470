import produce from 'immer';

import {
  ACTION_TYPES,
  TCategoriesState,
  ICategoriesAction,
} from '../categories.constants';
import { TCategory } from '../../../models';
import { TCategoryModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { compareCategories } from '../events.sort';

// Reducer
export const fetchListReducer = (
  state: TCategoriesState,
  action: ICategoriesAction
): TCategoriesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TCategoryModel>;
      return produce(state, (draft) => {
        draft.status = 'done';
        draft.items = data.map<TCategory>((item) => {
          return { ...item, order: item.order || 0 };
        });
        draft.items.sort(compareCategories);
      });
    default:
      return state;
  }
};
