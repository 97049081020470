import { Immutable } from 'immer';

import { setPanelDisplayReducer } from './produceState/panel/display';
import { setEventReducer } from './produceState/event/setEvent';
import { setTetiereDisplayReducer } from './produceState/tetiere/display';
import { setTetiereInfosReducer } from './produceState/tetiere/infos';
import { setIsAdminReducer } from './produceState/admin/setIsAdmin';

// constants
import { TEvent } from '../../../redux/models';

const PREFIX = 'layout/';
export const ACTION_TYPES = {
  PANEL: {
    DISPLAY: `${PREFIX}PANEL_DISPLAY`,
  },
  EVENT: {
    SET_ITEM: `${PREFIX}EVENT_SET_ITEM`,
  },
  TETIERE: {
    DISPLAY: `${PREFIX}TETIERE_DISPLAY`,
    INFOS: `${PREFIX}TETIERE_INFOS`,
  },
  NAVIGATION: {
    DISPLAY: `${PREFIX}NAVIGATION_DISPLAY`,
  },
  SET_ISADMIN: `${PREFIX}SET_ISADMIN`,
};

export type TLayoutState = Immutable<{
  panel: {
    left: {
      display: boolean;
    };
    bottom: {
      display: boolean;
    };
  };
  tetiere: TLayoutTetiere;
  navigation: TLayoutNavigation;
  event: TEvent | null;
  isAdmin: boolean;
  animate: 'animate2' | 'animate';
}>;

export type TLayoutTetiere = {
  display: boolean;
  img: string | null;
  intro: string | null;
  title: string | null;
  description: string | null;
};

export type TLayoutNavigation = {
  display: boolean;
};

export type TTetiereInfos = Partial<Omit<TLayoutTetiere, 'display'>>;

export type TPanel = 'left' | 'bottom';

export type TLayoutAction = { type: string } & (
  | Parameters<typeof setPanelDisplayReducer>['1']
  | Parameters<typeof setEventReducer>['1']
  | Parameters<typeof setTetiereDisplayReducer>['1']
  | Parameters<typeof setTetiereInfosReducer>['1']
  | Parameters<typeof setIsAdminReducer>['1']
);

export type TLayoutDispatcher = {
  panel: {
    display: (panel: TPanel, display: boolean) => void;
  };
  event: {
    setEvent: (event?: TEvent) => void;
  };
  tetiere: {
    display: (display: boolean) => void;
    setInfos: (infos?: TTetiereInfos | null) => void;
  };
  navigation: {
    display: (display: boolean) => void;
  };
  setIsAdmin: (isAdmin: boolean) => void;
};

export const defaultState: TLayoutState = {
  panel: {
    left: {
      display: true,
    },
    bottom: {
      display: true,
    },
  },
  tetiere: {
    display: false,
    img: null,
    intro: null,
    title: null,
    description: null,
  },
  navigation: {
    display: true,
  },
  event: null,
  isAdmin: false,
  animate: 'animate',
};
