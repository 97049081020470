import produce from 'immer';

import {
  ACTION_TYPES,
  TProductsState,
  IProductsAction,
} from '../products.constants';

// Reducer
export const transverseReducer = (
  state: TProductsState,
  action: IProductsAction
): TProductsState => {
  switch (action.type) {
    default:
      return state;
  }
};
