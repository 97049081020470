import { TProduct } from 'app/redux/models';
import { OrderBy, compareString } from '../../../helpers/compare';

export const compareProduct = (
  product1: TProduct,
  product2: TProduct,
  orderBy = OrderBy.ASC
): number => {
  let compare = 0;

  compare = compareString(product1.title, product2.title, orderBy);

  return compare;
};
