import React from 'react';
import classnames from 'classnames';

// components
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ToastContainer } from '../components/Toast';

// styles
import './Layout.scss';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <ErrorBoundary>
      <div className={classnames('layout')}>
        <ToastContainer />
        <Header />
        <div className="layout-container jl_home_bw">
          <div className="container">
            <div className="row">
              <div className="col-md-12 jl_mid_main_3col">
                <div className="jl_3col_wrapin">
                  <div id="pl-4212" className="panel-layout">
                    <div className="layout-page">
                      <ErrorBoundary>
                        {children}
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>      
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};
