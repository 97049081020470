import produce from 'immer';

import {
  ACTION_TYPES,
  TPublishersState,
  IPublishersAction,
} from '../publishers.constants';
import { TPublisher } from '../../../models';
import { TPublisherModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';

// Reducer
export const fetchListReducer = (
  state: TPublishersState,
  action: IPublishersAction
): TPublishersState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TPublisherModel>;
      return produce(state, (draft) => {
        draft.status = 'done';
        draft.items = data.map<TPublisher>((item) => {
          return { ...item };
        });
      });
    default:
      return state;
  }
};
