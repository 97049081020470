import produce from 'immer';

import {
  ACTION_TYPES,
  TAuthorsState,
  IAuthorsAction,
} from '../authors.constants';

// Reducer
export const transverseReducer = (
  state: TAuthorsState,
  action: IAuthorsAction
): TAuthorsState => {
  switch (action.type) {
    default:
      return state;
  }
};
