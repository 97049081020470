import * as React from 'react';

export const ToastSuccessIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="8" fill="#5FDAA0" />
      <path
        d="M11.9236 15.3221L16.0162 10.4526C16.3384 10.0737 16.2815 9.52421 15.9026 9.20211C15.5236 8.89895 14.9741 8.93684 14.652 9.31579L11.2415 13.3705L9.57414 11.3811C9.25203 11.0021 8.70256 10.9453 8.32361 11.2674C7.94467 11.5895 7.88782 12.1389 8.20993 12.5179L10.5594 15.3221C10.7299 15.5305 10.9762 15.6442 11.2415 15.6442C11.5068 15.6442 11.7531 15.5305 11.9236 15.3221Z"
        fill="white"
      />
    </svg>
  );
};
