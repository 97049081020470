export const checkSticky = (
  div: HTMLDivElement | null,
  setIsSticky: (fixed: boolean) => void
): void => {
  if (div) {
    const { top } = div.getBoundingClientRect();
    setIsSticky(top <= 0);
  }
};

export const checkWidth = (
  div: HTMLDivElement | null,
  setWidth: (h: number) => void
): void => {
  if (div) {
    const h = getCurrentOffset(div, 'width');
    if (h) {
      setWidth(h);
    }
  }
};

export const checkHeight = (
  div: HTMLDivElement | null,
  setHeight: (h: number) => void
): void => {
  if (div) {
    const h = getCurrentOffset(div, 'height');
    if (h) {
      setHeight(h);
    }
  }
};

const getCurrentOffset = (
  div: HTMLDivElement | null,
  offset: 'height' | 'width'
): number | null => {
  if (div) {
    const internalBloc = div.children.item(0) as HTMLElement;
    if (internalBloc.children) {
      const bloc = internalBloc.children.item(0) as HTMLElement;
      switch (offset) {
        case 'height':
          return bloc.offsetHeight || null;
        case 'width':
          return bloc.offsetWidth || null;
      }
    }
  }
  return null;
};
