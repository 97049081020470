import * as ls from 'local-storage';

export const STORAGE_USER_KEY = 'current-user';

export const get = (): string => {
  return ls.get<string>(STORAGE_USER_KEY) || '';
};
export const set = (value: string | null): boolean => {
  if (value === null) {
    remove();
    return true;
  }
  return ls.set(STORAGE_USER_KEY, value);
};
export const remove = (): void => {
  return ls.remove(STORAGE_USER_KEY);
};
