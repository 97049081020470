import produce from 'immer';

import {
  ACTION_TYPES,
  TProductsState,
  IProductsAction,
  TProductEvent,
} from '../products.constants';
import { TProduct } from '../../../models';
import { TProductModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { compareProduct } from '../../products/products.sort';

const getNewEvents = (
  eventId,
  events: Array<TProductEvent> = [],
  newEvent: Partial<TProductEvent>
): Array<TProductEvent> => {
  const currentItem = events.find((event) => event.id === eventId);
  if (currentItem) {
    return events.map((event) => {
      if (event.id === eventId) {
        return {
          ...event,
          ...newEvent,
        };
      }
      return event;
    });
  } else {
    return [
      ...events,
      {
        id: eventId,
        items: [],
        status: 'initial',
        ...newEvent,
      },
    ];
  }
};

// Reducer
export const fetchListByEventReducer = (
  state: TProductsState,
  action: IProductsAction
): TProductsState => {
  const { eventId } = action.meta || {};

  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST_BY_EVENT):
      return produce(state, (draft) => {
        draft.events = getNewEvents(eventId, draft.events, {
          status: 'loading',
        });
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST_BY_EVENT):
      return produce(state, (draft) => {
        draft.events = getNewEvents(eventId, draft.events, {
          status: 'error',
        });
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST_BY_EVENT):
      const data = action.payload?.data as Array<TProductModel>;
      return produce(state, (draft) => {
        const items = data.map<TProduct>((item) => {
          return { ...item };
        });
        items.sort(compareProduct);

        draft.events = getNewEvents(eventId, draft.events, {
          status: 'done',
          items,
        });
      });
    default:
      return state;
  }
};
