import produce from 'immer';

import { ACTION_TYPES, TEventsState, IEventsAction } from '../events.constants';
import { TEvent } from '../../../models';
import { TEventModel } from '../../../api/models';

import { REQUEST, SUCCESS, FAILURE } from '../../../constants';
import { compareEvent } from '../events.sort';

// Reducer
export const fetchListReducer = (
  state: TEventsState,
  action: IEventsAction
): TEventsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'loading';
      });
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return produce(state, (draft) => {
        draft.status = 'error';
      });
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      const data = action.payload?.data as Array<TEventModel>;
      return produce(state, (draft) => {
        const items = data.map<TEvent>((item) => {
          return { ...item };
        });
        items.sort(compareEvent);

        draft.status = 'done';
        draft.items = items;
      });
    default:
      return state;
  }
};
