import produce from 'immer';

import { ACTION_TYPES, TUsersState, IUsersAction } from '../users.constants';

// Reducer
export const transverseReducer = (
  state: TUsersState,
  action: IUsersAction
): TUsersState => {
  switch (action.type) {
    default:
      return state;
  }
};
