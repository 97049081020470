import { IAxiosAction } from '../../api/models';
import { IReduxAction } from '../../constants';

import { writeReduxMiddlewareGroupCollapsed } from '../../../helpers/console-log';

export const loggerMiddleware =
  ({ getState }) =>
  (next) =>
  (action: IAxiosAction<any, any> | IReduxAction<any>) => {
    const returnedValue = next(action);

    try {
      writeReduxMiddlewareGroupCollapsed(action, getState());
    } catch (err) {
      console.warn(err);
    }

    return returnedValue;
  };
