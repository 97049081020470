import React from 'react';

export const useInterval = (callback: () => void, delay = 300): void => {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);
};
