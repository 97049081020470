import React from 'react';
import classnames from 'classnames';
import { useField } from 'formik';

// components
import { Input, InputGroup, InputGroupText, InputProps } from 'reactstrap';
import { FormGroup } from './components/FormGroup';

// styles
import './FormInput.scss';

export interface IFormInputProps extends InputProps {
  name: string;
  label?: React.ReactNode;
  children?: React.ReactNode;
  subChildren?: React.ReactNode;
}

export const FormInput: React.FC<IFormInputProps> = ({
  className,
  name,
  label,
  children,
  subChildren,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <FormGroup
      className={classnames('form-input', className)}
      name={name}
      label={label}
      switch={props.type === 'switch'}
      disabled={props.disabled}
    >
      {props.type === 'email' ? (
        <InputGroup>
          <InputGroupText>@</InputGroupText>
          <Input 
            {...props}
            {...field}
            >
              {children}
          </Input>
        </InputGroup>
      ): (
        <Input 
          {...props}
          {...field}
          >
            {children}
        </Input>
      )}
      {subChildren}
    </FormGroup>
  );
};

FormInput.defaultProps = {
  className: undefined,
  children: null,
  subChildren: null,
};
