import { writeWebVitalsGroupCollapsed } from '../helpers/console-log';
import {
  onFCP,
  onLCP,
  onFID,
  onCLS,
  onTTFB,
  onINP,
  ReportOpts,
} from 'web-vitals/attribution';

const options: ReportOpts = { reportAllChanges: true };

const reportWebVitals = (onPerfEntry: any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onLCP(onPerfEntry, options);
    onFCP(onPerfEntry, options);
    onFID(onPerfEntry, options);
    onCLS(onPerfEntry, options);
    onTTFB(onPerfEntry, options);
    onINP(onPerfEntry, options);
  }
};

export const initializeWebVitals = () => {
  reportWebVitals(writeWebVitalsGroupCollapsed);
};
